import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
import axios from "axios"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.css"
Vue.use(VueAwesomeSwiper)

const baseApiUrl_CN = "https://cnhshy.qilushop.cn/api.php/";
const baseApiUrl_EN = "https://enhshy.qilushop.cn/api.php/";

const baseUrl_CN = "https://cnhshy.qilushop.cn";
const baseUrl_EN = "https://enhshy.qilushop.cn";

Vue.prototype.$baseUrl = ``;
Vue.prototype.$routerPush = router.push;
Vue.prototype.$axiosPost = axios.post;
Vue.prototype.$axios = axios;
Object.defineProperty(Vue.prototype, "$lang", {
  set(newValue) {
    window.localStorage.setItem("lang", newValue);
    if (newValue === "cn") {
      this.$baseUrl = baseUrl_CN;
    } else if (newValue === "en") {
      this.$baseUrl = baseUrl_EN;
    } else {
      throw new SyntaxError(`[switching lang]Unknown language:'${newValue}' except:[cn,en]`)
    }
  },
  get() {
    let lang = window.localStorage.getItem("lang");
    if (!lang || lang === "cn") {
      this.$baseUrl = baseUrl_CN;
    } else {
      this.$baseUrl = baseUrl_EN;
    }
    return lang;
  }
})

Vue.prototype.$post = function (url) {
  // 如果是英文站 则需要根据EN站的栏目ID替换掉现有的ID

  if (!this.$lang) {
    this.$lang = "cn";
  }
  if (this.$lang === "cn") {
    return axios.post(`${baseApiUrl_CN}${url}`);
  } else if (this.$lang === "en") {
    return axios.post(`${baseApiUrl_EN}${url}`);
  } else {
    throw new SyntaxError(`[switching api]Unknown language:'${this.$lang}'`);
  }

}
axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded';
Vue.use(Element)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
