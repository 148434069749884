import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/Index.vue'),
    children: [{
      path: "/",
      name: "home",
      component: () => import("../views/Home.vue")
    }, {
      name: "homeNewsDetail",
      path: "/homeNewsDetail",
      component: () => import("../components/News/NewsDetail.vue")
    }, {
      // 关于我们
      path: "/aboutus",
      name: "aboutus",
      component: () => import("../views/About.vue"),
      children: [{
        path: "/profile",
        component: () => import("../components/About/AboutCompanyProfile.vue"),
      }, {
        path: "/org",
        component: () => import("../components/About/AboutCompanyOrganization.vue"),
      }, {
        path: "/admin",
        component: () => import("../components/About/AboutCompanyAdmin.vue"),
      }]
    }, {
      // 新闻中心
      path: "/news",
      component: () => import("../views/News.vue"),
      children: [{
        path: "/newslist",
        component: () => import("../components/News/NewsList.vue")
      }, {
        path: "/companyAC",
        component: () => import("../components/News/CompanyActivity.vue")
      }, {
        path: "/enterpriseAC",
        component: () => import("../components/News/EnterpriseActivity.vue")
      }, {
        name: "newsDetail",
        path: "/newsDetail",
        component: () => import("../components/News/NewsDetail.vue")
      }]
    }, {
      // 业务领域
      path: "/productList",
      component: () => import("../views/ProductList.vue"),
    }, {
      // 科技创新
      path: "/technews",
      component: () => import("../views/TechNews.vue"),
      children: [{
        path: "/techoverview",
        component: () => import("../components/TechNews/TechOverview.vue")
      }, {
        path: "/techachi",
        component: () => import("../components/TechNews/TechAchievement.vue")
      }, {
        path: "/enterprisequalifi",
        component: () => import("../components/TechNews/EnterpriseQualification.vue")
      }]
    }, {
      // 党建工作
      path: "/partyworkhome",
      component: () => import("../views/PartyWorkHome.vue"),
      children: [{
        path: "/partywork",
        component: () => import("../components/PartyWork/PartyWork.vue")
      }, {
        path: "/partyActivity",
        component: () => import("../components/PartyWork/PartyActivity.vue")
      }, {
        name: "partyworkDetail",
        path: "/partyworkDetail",
        component: () => import("../components/PartyWork/PartyworkDetail.vue")
      },]
    }, {
      // 党建专题
      path: "/partyworkTopic",
      component: () => import("../views/PartyWorkTopic.vue"),
      children: [{
        path: "/partyworkEducation",
        component: () => import("../components/PartyWorkTopic/PartyworkEducation.vue")
      }, {
        // 两学一做
        path: "/partyworkPart1",
        component: () => import("../components/PartyWorkTopic/PartyworkPart1.vue")
      }, {
        // 不忘初心 牢记使命
        path: "/partyworkPart2",
        component: () => import("../components/PartyWorkTopic/PartyworkPart2.vue")
      }, {
        name: "partyworkTopicDetail",
        path: "/partyworkTopicDetail",
        component: () => import("../components/PartyWorkTopic/PartyworkTopicDetail.vue")
      }]
    }, {
      path: "/humanresource",
      component: () => import("../views/HumanResource.vue"),
      children: [{
        path: "/socialrecruitment",
        component: () => import("../components/HumanResource/SocialRecruitment.vue")
      }, {
        path: "/campusrecruitment",
        component: () => import("../components/HumanResource/CampusRecruiting.vue")
      }, {
        name: "recruitingDetail",
        path: "/recruitingDetail",
        component: () => import("../components/HumanResource/RecruitingDetail.vue")
      }]
    }, {
      path: "/investorRelations",
      component: () => import("../views/InvestorRelations.vue"),
      children: [{
        path: "/basic",
        component: () => import("../components/InvestorRelations/Basic.vue")
      }, {
        path: "/board",
        component: () => import("../components/InvestorRelations/Board.vue")
      }, {
        path: "/relations",
        component: () => import("../components/InvestorRelations/Relations.vue")
      }]
    }, {
      path: "/infodisclose",
      component: () => import("../views/InformationDisclosure.vue"),
      children: [{
        path: "/leaderinfo",
        component: () => import("../components/InformationDisclosure/LeaderInfo.vue")
      }, {
        path: "/rmxx",
        component: () => import("../components/InformationDisclosure/Rmxx.vue")
      }, {
        path: "/importantinfo",
        component: () => import("../components/InformationDisclosure/ImportantInfo.vue"),
      }, {
        path: "/otherdiscloseinfo",
        component: () => import("../components/InformationDisclosure/OtherDisclosureInfo.vue")
      }, {
        path: "/socialduty",
        component: () => import("../components/InformationDisclosure/SocialDuty.vue")
      }, {
        name: "infodisclosuredetail",
        path: "/infodisclosuredetail",
        component: () => import("../components/News/NewsDetail.vue")
      }]
    }, {
      path: "/contactus",
      component: () => import("../views/ContactUs.vue"),
      children: [{
        path: "/contactway",
        component: () => import("../components/ContactUs/ContactWay.vue")
      }, {
        path: "/infofeedback",
        component: () => import("../components/ContactUs/InfoFeedback.vue")
      }]
    }, {
      path: "/companyProfile/zjsdtt",
      component: () => import("../views/CompanyProfile/zjsd.vue")
    }, {
      path: "/companyProfile/jshdtt",
      component: () => import("../views/CompanyProfile/jshd.vue")
    }, {
      path: "/companyProfile/jszgdlsb",
      component: () => import("../views/CompanyProfile/jszg.vue")
    }, {
      path: "/companyProfile/ahhytt",
      component: () => import("../views/CompanyProfile/ahhy.vue")
    }, {
      path: "/companyProfile/cqstttzz",
      component: () => import("../views/CompanyProfile/cqst.vue")
    }, {
      path: "/companyProfile/sxyhfgs",
      component: () => import("../views/CompanyProfile/yhgs.vue")
    }, {
      path: "/companyProfile/qdhm",
      component: () => import("../views/CompanyProfile/qdhm.vue")
    }, {
      path: "/companyProfile/404",
      component: () => import("../views/CompanyProfile/Empty.vue")
    }
    ]
  },

]

const router = new VueRouter({
  routes
})

export default router
